import React, { useState, useEffect } from "react"
import { db } from "../../../utils/firebase"
import moment from "moment"

/**
 * UI components
 */
import Input from "../../ui/inputs/input"
import Textarea from "../../ui/inputs/textarea"

// Different options for the buggy flags
const buggyOptions = {
	true: "Buggies are included in your package.",
	false: "Buggies not included.",
	no: "Buggies not included.",
	yes_reserved: "We have reserved buggies for you at the resort be paid locally, these are subject to availability.",
	yes_greenfee: "Buggies are included in your package.",
	yes_prepaid: "Buggies are included in your package.",
}

/**
 * Functional component to return the round component
 */
function Round(props) {
	const [course, setCourse] = useState({})
	const [courseID, setCourseID] = useState("")
	const [teeTime, setTeeTime] = useState(0)
	const [buggies, setBuggies] = useState(false)
	const [buggiesString, setBuggiesString] = useState("")

	/**
	 * Deconstruct the booking & round IDs from the props
	 */
	const { bookingID, roundID, index } = props

	/**
	 * On component load
	 */
	useEffect(() => {
		/**
		 * Get the round document from the database
		 */
		db.doc(`bookings/${bookingID}/rounds/${roundID}`)
			.get()
			.then((roundDoc) => {
				/**
				 * Deconstruct the data from the document
				 */
				const { buggies, comp_buggies, courseID, tee_time } = roundDoc.data()

				// Create a new string for the reserved buggies
				let reservedString = ""

				// Allocate the correct string for whether the buggies are reserved
				if (buggyOptions[comp_buggies]?.length > 0 && buggyOptions[comp_buggies] !== false && comp_buggies !== "false" && comp_buggies !== false) {
					reservedString = buggyOptions[comp_buggies]
				} else {
					if (buggies) {
						reservedString = "Buggies are included in your package."
					} else {
						reservedString = "Buggies not included."
					}
				}

				/**
				 * Generate a date from the
				 */
				const teeTimeDate = moment(tee_time?.seconds, "X").format("DD/MM/YYYY HH:mm")
				/**
				 * Update the state with these details
				 */
				setCourseID(courseID || "")
				setTeeTime(teeTimeDate || 0)
				setBuggies(buggies || false)
				setBuggiesString(reservedString)
			})
	}, [])

	/**
	 * When the course ID is updated in the state
	 */
	useEffect(() => {
		/**
		 * Fetch the course from the database
		 */
		courseID &&
			db
				.doc(`courses/${courseID}`)
				.get()
				.then((courseDoc) => {
					/**
					 * Decosntruct the course data
					 */
					const { name, email } = courseDoc.data()
					/**
					 * Set the data into the state
					 */
					setCourse({ name, email })
				})
	}, [courseID])

	return (
		<table className="booking-table">
			<tbody>
				<tr>
					<td colSpan="2">
						<div className="table-room-lower-border" />
					</td>
				</tr>
				<tr className="table-sub-heading left-align">
					<td>Round #{index + 1}</td>
				</tr>
				<tr>
					<td>Course name</td>
					<td>
						<Input
							type="text"
							value={course?.name}
							placeholder="No course chosen yet"
							readOnly={true}
						/>
					</td>
				</tr>
				<tr>
					<td>Tee time</td>
					<td>
						<Input
							type="text"
							value={teeTime}
							placeholder="Tee time not yet set"
							readOnly={true}
						/>
					</td>
				</tr>
				<tr>
					<td>Buggies</td>
					<td>
						<Textarea
							type="text"
							value={buggiesString}
							readOnly={true}
							rows={3}
						/>
					</td>
				</tr>
			</tbody>
		</table>
	)
}

export default Round
