import { useEffect, useState, useContext } from "react"
import { auth, db, storage } from "../../../utils/firebase"
import { QuoteContext } from "../context/context"
import { PDFDocument, rgb } from "pdf-lib"
import fontkit from "@pdf-lib/fontkit"
import firebase from "firebase"

// Utility functions
import { writeAgentBio, writeFooters, writeHeaders, writeIntroduction, writeItinerary } from "./pdf-utils/drawText"

// UI components
import { CheckIcon, LinkIcon, TrashIcon } from "../../../utils/svgs-v2"
import Loading from "../../../components/app/loading/loading"

// Returns the markup for an option now
export default function ConfirmedPackage({ quoteID, details, onDelete, customIntroduction, replaceFields, offsetYear }) {
	const [loading, setLoading] = useState(true)
	const [documentURL, setDocumentURL] = useState("")

	// Pull the update method from the quote context
	const { ctxSavePDFLink, ctxClientFirstName, ctxClientLastName } = useContext(QuoteContext)

	// On component mount, start processing the generation immediately when a quoteID is ready
	useEffect(() => {
		// If we have the quoteID is present in the props
		if (quoteID && quoteID.length > 0) {
			generatePDFQuote()
		}
	}, [quoteID])

	// Process the generation of the PDF quote
	const generatePDFQuote = async () => {
		// Base URL for the original template PDF
		const baseTemplate =
			"https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/_system%2Fdocuments%2Fquoting%2FProject%20Eagle%20-%2031-10-2024.pdf?alt=media&token=e4a5408a-2e14-4dee-af1d-479419d4a3a5"

		// Fetch this template as an array buffer
		const templatePDFBytes = await fetch(baseTemplate).then((response) => response.arrayBuffer())

		// Load the document up within the pdf-ib library
		const quotePDFDocument = await PDFDocument.load(templatePDFBytes)

		// Register the font kit with the PDF library
		quotePDFDocument.registerFontkit(fontkit)

		// Download a buffer version of the poppins font
		const poppins300Bytes = await fetch(
			"https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/_system%2Ffonts%2FPoppins-Light.otf?alt=media&token=d9afcf78-2487-4443-b2cf-92e03fdd39fd"
		).then((res) => res.arrayBuffer())
		const poppins400Bytes = await fetch(
			"https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/_system%2Ffonts%2FPoppins-Regular.otf?alt=media&token=99ebe70d-f9f6-454c-86f3-c00b9ae60423"
		).then((res) => res.arrayBuffer())
		const poppins600Bytes = await fetch(
			"https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/_system%2Ffonts%2FPoppins-SemiBold.otf?alt=media&token=71d97025-3d30-4a6c-b662-4e7fdf051350"
		).then((res) => res.arrayBuffer())
		const poppins800Bytes = await fetch(
			"https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/_system%2Ffonts%2FPoppins-ExtraBold.otf?alt=media&token=7bc212c3-b343-4d2f-8685-1ab6e785833a"
		).then((res) => res.arrayBuffer())

		// Embed the different fonts into the PDF
		const poppins300 = await quotePDFDocument.embedFont(poppins300Bytes)
		const poppins400 = await quotePDFDocument.embedFont(poppins400Bytes)
		const poppins600 = await quotePDFDocument.embedFont(poppins600Bytes)
		const poppins800 = await quotePDFDocument.embedFont(poppins800Bytes)

		// Pull out the various pages on the PDF
		const pages = quotePDFDocument.getPages()
		const firstPage = pages[0]

		// Setup some height/width variables for the actual page size
		const { height, width } = firstPage.getSize()

		// Get the current agent details
		const agent = await db
			.doc(`users/${auth.currentUser.uid}`)
			.get()
			.then((agentDoc) => {
				return {
					...agentDoc.data(),
					name: `${agentDoc.data().first_name} ${agentDoc.data().last_name}`,
					phone: agentDoc.data().phone,
					email: agentDoc.data().email,
				}
			})

		// Write the introduction paragraph
		await writeIntroduction({
			quotePDFDocument,
			agent,
			clientName: `${ctxClientFirstName} ${ctxClientLastName}`,
			customIntroduction,
			introductionPage: pages[0],
			quoteDetails: details,
			sizes: { height, width },
			fonts: { poppins400, poppins600, poppins800 },
		})

		// Write the itinerary details onto the page
		await writeItinerary({ itineraryPage: pages[1], quoteDetails: details, sizes: { height }, fonts: { poppins400, poppins600 }, replaceFields, offsetYear })

		// Write the agent bio
		await writeAgentBio({ quotePDFDocument, agent, agentBioPage: pages[2], quoteDetails: details, sizes: { height, width }, fonts: { poppins400, poppins600, poppins800 } })

		// Write the headers and footers onto the document
		await writeHeaders({ details, quotePDFDocument, sizes: { height, width }, fonts: { poppins400, poppins600, poppins800 } })
		await writeFooters({ agent, quotePDFDocument, sizes: { width }, fonts: { poppins300, poppins600 } })

		// Save the new PDF document
		const newPDFBytes = await quotePDFDocument.save()

		// Then we want to upload this into storage
		uploadPDFQuote(newPDFBytes)
	}

	// Handle the actual upload process of the file into the storage bucket
	const handleDocumentUpload = async (documentUploadRef) => {
		// Attach a listener onto the upload job to stream the progress into state
		const monitorUploadProgress = (upload) => {
			return new Promise((resolve, reject) => {
				// Setup the listener on the state_change variable for the upload
				upload.on(
					"state_changed",
					(snapshot) => {
						// Calculate a percentage for the upload progress
						const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
						console.log(`Uploading quote document...${progress}%`)
					},
					(error) => console.log(error),
					() => {
						resolve()
					}
				)
			})
		}

		// Return a promise to halt execution until the upload is complete
		return new Promise((resolve, reject) => {
			// Attach the monitor to the uploaded file
			monitorUploadProgress(documentUploadRef).then(() => {
				// When it's complete, get the downloadURL from the callback
				documentUploadRef.snapshot.ref.getDownloadURL().then(async (url) => {
					// Write this document into the quotes 'documents' subcollection
					await db.doc(`quotes/${quoteID}/documents/${details.reference}`).set({
						pdf_url: url,
						created: firebase.firestore.FieldValue.serverTimestamp(),
					})

					// Resolve the promise
					resolve(url)
				})
			})
		})
	}

	// Upload the PDF quote into storage
	const uploadPDFQuote = async (PDFBytes) => {
		// Establish some metadata for the document
		const meta = {
			contentType: "application/pdf",
			customMetadata: {
				fileID: details.reference,
				uploadedBy: auth.currentUser.uid,
			},
		}

		// Upload the document into storage
		const upload = storage.ref(`quotes/${quoteID}/${details.reference}`).put(PDFBytes, meta)

		// Wait for the document to be uploaded into the storage bucket
		const uploadedDocumentURL = await handleDocumentUpload(upload)

		// Convert the unit array data into a base64 string
		const unitArrayToBase64 = async (unitArrayData) => {
			// Use a FileReader to generate a base64 data URI
			const base64url = await new Promise((r) => {
				const reader = new FileReader()
				reader.onload = () => r(reader.result)
				reader.readAsDataURL(new Blob([unitArrayData]))
			})

			// Complete result would show: "data:application/octet-stream;base64,<the data>", so just
			// return the remainder of the string behind the meta at the start
			return base64url.split(",", 2)[1]
		}

		// Write the new document into a base64 for embedding onto the email
		const documentAsBase64 = await unitArrayToBase64(PDFBytes)

		// Set the state and update the package in the quote context
		setLoading(false)
		setDocumentURL(uploadedDocumentURL)
		ctxSavePDFLink(details._id, uploadedDocumentURL, documentAsBase64)
	}

	return (
		<div className="quote-option-row">
			<div className="quote-option-row-title quote-option-pdf-title">
				{details.reference}

				{!loading && <CheckIcon />}
			</div>

			{loading && (
				<div className="">
					<Loading hideLabel={true} />
				</div>
			)}

			{!loading && (
				<div className="quote-options-end-actions">
					<a
						className="quote-option-end-action"
						href={documentURL}
						target="_blank"
						rel="noreferrer">
						<LinkIcon />
					</a>

					<div
						className="quote-option-end-action"
						onClick={() => onDelete()}>
						<TrashIcon />
					</div>
				</div>
			)}
		</div>
	)
}
