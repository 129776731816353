import React, { useState, useEffect, useRef } from "react"
import { CALENDAR_MONTHS } from "../../utils/exports/months"
import { db } from "../../utils/firebase"
import firebase from "firebase"
import moment from "moment"
import "./buggies.scss"

// UI components
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Select from "../../components/ui/select/select"
import Button from "../../components/ui/button/button"
import Table from "../../components/structure/table/table"
import BuggiesRow from "./buggiesRow"
import Checkbox from "../../components/ui/checkbox/checkbox"

// Functional component to return a full list of bookings where buggies are required
export default function Buggies() {
	const [loading, setLoading] = useState(false)
	const [bookings, setBookings] = useState([])
	const [totalSize, setTotalSize] = useState(0)
	const [progressCount, setProgressCount] = useState(0)
	const [onlyPrepaid, setOnlyPrepaid] = useState(false)

	const [years, setYears] = useState({})

	const [month, setMonth] = useState("")
	const [year, setYear] = useState("")

	const [buggiesCost, setBuggiesCost] = useState(0)
	const [buggiesCostLeft, setBuggiesCostLeft] = useState(0)

	const totalBuggiesCost = useRef({})
	const totalBuggiesCostLeft = useRef({})

	// On component load
	useEffect(() => {
		// Create an empty object for storing the years
		let yearsObj = {}

		// Get next year as a number
		let nextYear = Number(moment().startOf(year).add(2, "years").format("YYYY"))

		// Run through a for loop to print out the years
		for (let i = nextYear; i >= 2018; i--) {
			yearsObj = {
				[i]: i,
				...yearsObj,
			}
		}

		// Set the years into the state for the dropdown
		setYears(yearsObj)
	}, [])

	const findBookingRounds = async () => {
		// Clear the state and set the loading
		setLoading(true)

		totalBuggiesCost.current = {}
		totalBuggiesCostLeft.current = {}

		setBookings([])
		setTotalSize(0)
		setProgressCount(0)
		setBuggiesCost(0)
		setBuggiesCostLeft(0)

		// Get the start/end timestamps in milliseconds
		let fetchStart = 0
		let fetchEnd = 0

		// Are we looking for a specific month or year?
		if (month) {
			fetchStart = moment(`${month}-${year}`, "MMMM-YYYY").startOf("month").valueOf()
			fetchEnd = moment(`${month}-${year}`, "MMMM-YYYY").endOf("month").valueOf()
		} else if (year) {
			fetchStart = moment(year, "YYYY").startOf("year").valueOf()
			fetchEnd = moment(year, "YYYY").endOf("year").valueOf()
		} else {
			setLoading(false)
			return
		}

		// Build the timestamps for firebase to recognise
		const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart)
		const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd)

		// Fetch all the rounds that match those parameters
		const allRounds = await db
			.collectionGroup("rounds")
			.where("tee_time", ">=", startTimestamp)
			.where("tee_time", "<=", endTimestamp)
			.orderBy("tee_time", "asc")
			.get()
			.then(async (roundDocs) => {
				const roundsArray = []
				setTotalSize(roundDocs.size)
				let progress = 1

				for (const doc of roundDocs.docs) {
					setProgressCount(progress)

					// Write the buggies cost into the state refs
					totalBuggiesCost.current[doc.id] = doc.data()?.buggies_cost || 0
					if (!doc.data()?.buggies_booked) {
						totalBuggiesCostLeft.current[doc.id] = doc.data()?.buggies_cost || 0
					}

					roundsArray.push({
						bookingID: doc.ref.parent.parent.id,
						roundID: doc.id,
						// ...bookingData,
						round_details: {
							...doc.data(),
						},
					})

					progress++
				}

				return roundsArray
			})

		// Set the rounds into the state
		setBookings(allRounds)

		// Reset the loading state
		setLoading(false)

		// Then update the totals
		setBuggiesCost(
			Object.values(totalBuggiesCost.current)
				.reduce((a, b) => a + b, 0)
				?.toLocaleString()
		)
		setBuggiesCostLeft(
			Object.values(totalBuggiesCostLeft.current)
				.reduce((a, b) => a + b, 0)
				?.toLocaleString()
		)
	}

	return (
		<Tile fullPage={true}>
			<Title className="flex has-select-field">
				<h1>Buggy Bookings</h1>
			</Title>

			<div className="page-filters flights">
				<Select
					label="Month:"
					placeholder="Month:"
					value={month}
					activeOnHover={true}
					onSelect={(option) => setMonth(option.option)}
					options={CALENDAR_MONTHS}
				/>

				<Select
					label="Year:"
					placeholder="Year:"
					value={year}
					activeOnHover={true}
					onSelect={(option) => setYear(option.option)}
					options={years}
				/>

				<div>
					<Button
						filters={true}
						disabled={(!month || !year) && !year}
						label="Fetch bookings"
						loadingText="Fetching stats..."
						loading={loading}
						onClick={() => findBookingRounds()}
					/>
				</div>

				{totalSize > 0 && totalSize !== progressCount && (
					<div className="fetch-progress">
						<small>Fetching progress:</small>
						<p>
							{progressCount}/{totalSize}
						</p>
					</div>
				)}

				<Checkbox
					label="Only show prepaid"
					checked={onlyPrepaid}
					onClick={() => setOnlyPrepaid(!onlyPrepaid)}
				/>
			</div>

			<Table
				className="travellers-table"
				headings={["Agent", "Reference", "Lead pax", "Travelling", "Tee time", "No. of pax", "Location", "Green fee (per golfer)", "Buggies cost", "Buggies type", "Buggies booked", ""]}
				noResults={Object.entries(bookings).length === 0}
				noResultsMessage={"No results matching those filters"}>
				{bookings.map((booking) => {
					if (onlyPrepaid && booking?.round_details?.comp_buggies !== "yes_prepaid") {
						return null
					} else {
						return (
							<BuggiesRow
								key={booking?.roundID}
								id={booking?.roundID}
								details={booking}
							/>
						)
					}
				})}

				<tr className="totals-row">
					<td colSpan="8">Totals:</td>
					<td>
						£{isNaN(buggiesCost?.toLocaleString()) ? 0 : buggiesCost?.toLocaleString() || 0} (£{isNaN(buggiesCostLeft?.toLocaleString()) ? 0 : buggiesCostLeft?.toLocaleString() || 0})
					</td>
					<td colSpan="5"></td>
				</tr>
			</Table>
		</Tile>
	)
}
