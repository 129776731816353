import { db } from "./firebase"
import moment from "moment"

/**
 * Take in a booking ID and process all the hotels, rounds and transfer prices to calculate
 * supplier invoices as well as calculations for exchange rates before writing the new values
 * into the database.
 */
export const updateBookingMargins = async (bookingID) => {
	/**
	 * Pull the booking data from the database
	 */
	const bookingData = await db
		.doc(`bookings/${bookingID}`)
		.get()
		.then((bookingDoc) => {
			/**
			 * Return the booking data in full
			 */
			return { ...bookingDoc.data() }
		})

	// Get the check_in date from the booking
	const marginChange = moment("01/03/2024", "DD/MM/YYYY")
	const checkInDate = moment(bookingData.check_in?.seconds, "X")

	/**
	 * Deconstruct the required data from the booking document
	 */
	const { customer_price, exchange_rate, flights_cost, hotels_cost, miscellaneous_costs, rounds_cost, buggies_cost, transfers_cost } = bookingData.margins

	/**
	 * Work out the costs as a fixed value to 2 decmimal places
	 */
	const hotelsFixed = (hotels_cost / exchange_rate).toFixed(2)
	const roundsFixed = (rounds_cost / exchange_rate).toFixed(2)
	const buggiesFixed = (buggies_cost / exchange_rate).toFixed(2)
	const transfersFixed = (transfers_cost / exchange_rate).toFixed(2)

	/**
	 * Get each as a number value
	 */
	const cost_hotels = Number.parseFloat(hotelsFixed) || 0
	const cost_rounds = Number.parseFloat(roundsFixed) || 0
	const cost_buggies = Number.parseFloat(buggiesFixed) || 0
	const cost_transfers = Number.parseFloat(transfersFixed) || 0
	const cost_flights = Number.parseFloat(flights_cost) || 0
	const cost_miscellaneous = Number.parseFloat(miscellaneous_costs) || 0

	/**
	 * Add these values all together to get the total supplier invoice amount
	 */
	const total_invoices = cost_hotels + cost_rounds + cost_transfers + cost_flights + cost_miscellaneous + cost_buggies

	/**
	 * Calculate the profit and the margin for the sales agent
	 */
	const total_profit = Number.parseFloat(customer_price - total_invoices)
	let total_margin = Number.parseFloat((total_profit / 10 / 2).toFixed(2))

	/**
	 * If the check in date is before the margin change date, then the margin is 10%
	 */
	if (checkInDate.isBefore(marginChange)) {
		total_margin = Number.parseFloat((total_profit / 10).toFixed(2))
	}
	/**
	 * Update the booking document
	 */
	return await db.doc(`bookings/${bookingID}`).set(
		{
			margins: {
				agents_margin_10: Number.parseFloat((total_profit / 10).toFixed(2)),
				agents_margin: Number.parseFloat(total_margin),
				profit: Number.parseFloat(total_profit),
				supplier_invoices: Number.parseFloat(total_invoices),
			},
		},
		{ merge: true }
	)
}

/**
 * Update teh pricing on the booking. This is often called when the golfers value is changed and the
 * hotel & round prices need matching up again in the database to reflect the new values.
 */
export const updateBookingTotalPricing = async (bookingID) => {
	/**
	 * Pull the booking data from the database
	 */
	const bookingData = await db
		.doc(`bookings/${bookingID}`)
		.get()
		.then((bookingDoc) => {
			/**
			 * Return the booking data in full
			 */
			return { ...bookingDoc.data() }
		})
	/**
	 * Deconstruct the required data from the booking document
	 */
	const { golfers, hotels_costs, round_costs } = bookingData
	/**
	 * Create a running total of all round prices
	 */
	let runningTotalRounds = 0
	/**
	 * Loop through each of the round prices on the booking
	 */
	if (round_costs && Object.values(round_costs).length > 0) {
		Object.values(round_costs).forEach((roundCost) => {
			/**
			 * Add it to the running total
			 */
			runningTotalRounds += Number.parseFloat(golfers * roundCost)
		})
	}
	/**
	 * Get the round costs to the nearest 2 decimal places
	 */
	const totalRoundsCost = Number.parseFloat(runningTotalRounds.toFixed(2))
	/**
	 * Create a running total of all hotel prices
	 */
	let runningTotalHotels = 0
	/**
	 * Loop through each of the hotel prices on the booking
	 */
	Object.values(hotels_costs).forEach((hotelCost) => {
		/**
		 * Add it to the running total
		 */
		runningTotalHotels += hotelCost
	})
	/**
	 * Get the hotel costs to the nearest 2 decimal places
	 */
	const totalHotelsCost = Number.parseFloat(runningTotalHotels.toFixed(2))
	/**
	 * Push the new global status onto the booking document
	 */
	return await db.doc(`bookings/${bookingID}`).set(
		{
			margins: {
				hotels_cost: totalHotelsCost,
				rounds_cost: totalRoundsCost,
			},
		},
		{ merge: true }
	)
}
