import { auth, db } from "./firebase"
import { ROOM_TYPES } from "./exports/room-types"
import { BOARD_TYPES } from "./exports/board-types"
import firebase from "firebase"
import moment from "moment"

/**
 * Mail imports
 */
import { sendEmail } from "./mail"
import * as mailTypes from "./exports/mail-types"

/**
 * Take in a heading and return a string for the table heading
 */
const tableHeading = (heading, extraPadding) => {
	/**
	 * Initiate a new empty array
	 */
	let html_string = []
	/**
	 * If we need to add extra padding
	 */
	if (extraPadding) {
		/**
		 * Add an empty table row above the heading
		 */
		html_string.push("<tr>")
		html_string.push(`<td colspan='2' style='padding-top:12px;'></td>`)
		html_string.push("</tr>")
	}
	/**
	 * Push the data to the array
	 */
	html_string.push("<tr>")
	html_string.push(`<td colspan='2' style='color:#fff;padding-top:5px;padding-bottom:5px;text-align:center;font-weight:500;background-color:#000;'>${heading}</td>`)
	html_string.push("</tr>")
	/**
	 * Add an empty table row beneath the heading
	 */
	html_string.push("<tr>")
	html_string.push(`<td colspan='2' style='padding-top:6px;'></td>`)
	html_string.push("</tr>")
	/**
	 * Join the array and return as a string
	 */
	return html_string.join("")
}

/**
 * Take in a table row heading and data and return a string for the row
 */
const tableRow = (heading, data) => {
	/**
	 * Initiate a new empty array
	 */
	let html_string = []
	/**
	 * Push the data to the array
	 */
	html_string.push("<tr>")
	html_string.push(`<td style='padding-right:25px;padding-bottom:5px;font-weight:500;width:160px;'>${heading}</td>`)
	html_string.push(`<td style='padding-bottom:5px;'>${data}</td>`)
	html_string.push("</tr>")
	/**
	 * Join the array and return as a string
	 */
	return html_string.join("")
}

/**
 * Take in a table row heading and data and return a string for the row
 */
const tableRowSpace = () => {
	/**
	 * Initiate a new empty array
	 */
	let html_string = []
	/**
	 * Add an empty table row above the spacer
	 */
	html_string.push("<tr>")
	html_string.push(`<td colspan='2' style='padding-top:4px;'></td>`)
	html_string.push("</tr>")
	/**
	 * Push the data to the array
	 */
	html_string.push("<tr>")
	html_string.push(`<td colspan='2' style='background-color:#eff2f5;height:2px;'></td>`)
	html_string.push("</tr>")
	/**
	 * Add an empty table row beneath the spacer
	 */
	html_string.push("<tr>")
	html_string.push(`<td colspan='2' style='padding-top:4px;'></td>`)
	html_string.push("</tr>")
	/**
	 * Join the array and return as a string
	 */
	return html_string.join("")
}

/**
 * Take in a bookingID to send the client an itinerary
 */
export const sendClientItinerary = async (bookingID, courses, hotels, transfers) => {
	/**
	 * Get the booking details from the database
	 */
	const booking = await db
		.doc(`bookings/${bookingID}`)
		.get()
		.then((bookingDoc) => {
			/**
			 * Return a complete object of all booking details
			 */
			return { ...bookingDoc.data() }
		})
	/**
	 * Fetch the name of the booking agent
	 */
	const agent = await db
		.doc(`users/${booking.agent}`)
		.get()
		.then((agentDoc) => {
			/**
			 * Get the agent data
			 */
			const { first_name, last_name, email } = agentDoc.data()
			/**
			 * Return the data in an object
			 */
			return { name: `${first_name} ${last_name}`, first_name, email }
		})
	/**
	 * Get the client details
	 */
	const client = await db
		.doc(`clients/${booking.client}`)
		.get()
		.then((clientDoc) => {
			/**
			 * Return a complete object of all the client details
			 */
			return { ...clientDoc.data() }
		})
	/**
	 * Get the booked date
	 */
	const getsFreeInsurance = moment(booking.booked?.seconds, "X").isAfter("2024-07-18T00:00:00Z")
	/**
	 * Get the group size by adding both golfers and non golfers
	 */
	const group_size = Number(booking.golfers + booking.non_golfers)
	/**
	 * Generate a balance due date as a string
	 */
	const balance_due_string = moment(booking?.balance_due?.seconds, "X").format("DD/MM/YYYY")
	/**
	 * Store a boolean value for whether the review object & bcc email flag on the booking exists
	 */
	const reviewBCCSent = booking.review?.itinerary_bcc_sent || false
	/**
	 * Setup an array to store the html content for the email
	 */
	let html_array = []
	/**
	 * Setup the table
	 */
	html_array.push("<table style='width:100%'>")
	html_array.push("<tbody>")
	/**
	 * Add the summary heading
	 */
	html_array.push(tableHeading("Booking summary"))
	/**
	 * Add the client name
	 */
	html_array.push(tableRow("Lead name:", `${client.first_name} ${client.last_name}`))
	/**
	 * Add the booking reference
	 */
	html_array.push(tableRow("Booking reference:", `${booking?.reference}`))
	/**
	 * Add the group size
	 */
	html_array.push(tableRow("Group size:", `${group_size}`))
	/**
	 * Add the golfers playing
	 */
	html_array.push(tableRow("Golfers playing:", `${booking?.golfers}`))
	/**
	 * Add the amount paid so far by the client
	 */
	html_array.push(tableRow("Paid so far:", `£${booking?.paid_by_client?.toLocaleString() || 0}`))
	/**
	 * Add the outstanding balance
	 */
	html_array.push(tableRow("Outstanding balance:", `£${booking?.balance_outstanding?.toLocaleString() || 0}`))
	/**
	 * Add the balance due date
	 */
	if (booking?.balance_due?.seconds && balance_due_string !== "Invalid date") {
		html_array.push(tableRow("Balance due:", `${balance_due_string}`))
	}
	/**
	 * Add the stableford points
	 */
	html_array.push(tableRow("Stableford points:", `You've earnt ${booking?.stableford?.amount} points`))
	/**
	 * Add the comments from the agent
	 */
	html_array.push(tableRow("Agent comments:", `${booking?.public_notes ? booking?.public_notes : "No comments"}`))
	/**
	 * Add the price lines heading
	 */
	html_array.push(tableHeading("Pricing", true))
	/**
	 * Loop through the prices subcollection on the booking
	 */
	await db
		.collection(`bookings/${bookingID}/prices`)
		.get()
		.then((priceDocs) => {
			/**
			 * Loop over the price line documents
			 */
			let index = 1
			priceDocs.forEach((priceDoc) => {
				/**
				 * Deconstruct the price data from the document
				 */
				const { name, price, quantity } = priceDoc.data()
				/**
				 * Push the price line into the email html array
				 */
				html_array.push(tableRow(`Pricing option #${index}`, name))
				html_array.push(tableRow("", `${quantity} x £${price.toLocaleString()}`))
				/**
				 * If the index is smaller than the amount of documents, add a spacer line in
				 */
				if (index < priceDocs.size) {
					html_array.push(tableRowSpace())
				}
				/**
				 * Increment the index
				 */
				index++
			})
		})
	/**
	 * Declare some transfer methods
	 */
	const transferMethods = {
		nothing: "No transfers",
		airport: "Airport",
		golf: "Golf",
		airport_and_golf: "Airport & Golf",
		car_hire: "Car Hire Included",
		euro_tunnel: "Euro tunnel",
	}
	/**
	 * Is there a transfer partner selected
	 */
	if (booking?.transfer) {
		/**
		 * Add the transfers heading
		 */
		html_array.push(tableHeading("Transfer information", true))
		/**
		 * Add the transfer name
		 */
		html_array.push(tableRow("Transfer partner:", transfers[booking?.transfer]))
		/**
		 * Add the transfer for field
		 */
		html_array.push(tableRow("Transfers covering:", transferMethods[booking?.transfer_type]))
		/**
		 * If the
		 */
		if (booking?.transfer_type !== "airport_and_golf") {
			/**
			 * Add the golf shuttles
			 */
			html_array.push(tableRow("Golf shuttles:", booking?.golf_shuttles ? "Included" : "Not included"))
		}
	} else if (!booking?.transfer && booking?.transfer_type && booking?.transfer_type !== "nothing") {
		/**
		 * Add the transfers heading
		 */
		html_array.push(tableHeading("Transfer information", true))
		/**
		 * Add the transfer for field
		 */
		html_array.push(tableRow("Covering:", transferMethods[booking?.transfer_type]))
	}
	/**
	 * Add the hotels heading
	 */
	html_array.push(tableHeading("Hotel information", true))
	/**
	 * Loop through the hotels subcollection on the booking
	 */
	await db
		.collection(`bookings/${bookingID}/hotels`)
		.orderBy("check_in")
		.get()
		.then((hotelDocs) => {
			/**
			 * Loop over the hotel documents
			 */
			let index = 1
			hotelDocs.forEach((hotelDoc) => {
				/**
				 * Deconstruct the price data from the document
				 */
				const { resortID, check_in, board_type, nights, rooms } = hotelDoc.data()
				/**
				 * Generate a readable string
				 */
				const checkInString = moment(check_in?.seconds, "X").format("DD/MM/YYYY")
				/**
				 * Push the round into the email html array
				 */
				html_array.push(tableRow(`Hotel #${index}`, hotels[resortID]))
				html_array.push(tableRow("Check in:", checkInString))
				html_array.push(tableRow("Nights:", nights))
				html_array.push(tableRow("Board type:", BOARD_TYPES[board_type]))
				/**
				 * Setup an array to store the various room types
				 */
				let room_options = {}
				/**
				 * Loop through the rooms on the hotel document
				 */
				rooms.forEach((room) => {
					/**
					 * Either increment the count by 1 or set it to 1 if it doesn't exist yet
					 */
					room_options[ROOM_TYPES[room.room_type]] = room_options[ROOM_TYPES[room.room_type]] + 1 || 1
				})
				/**
				 * Setup a new array for storing the room types
				 */
				let rooms_string = []
				/**
				 * Loop through the rooms on the hotel document
				 */
				for (const [room, count] of Object.entries(room_options)) {
					rooms_string.push(`${count} x ${room} <br />`)
				}
				/**
				 * Push the joined array onto the html array for the email
				 */
				html_array.push(tableRow("Rooms:", rooms_string.join("")))
				/**
				 * If the index is smaller than the amount of documents, add a spacer line in
				 */
				if (index < hotelDocs.size) {
					html_array.push(tableRowSpace())
				}
				/**
				 * Increment the index
				 */
				index++
			})
		})
	/**
	 * Add the rounds heading
	 */
	html_array.push(tableHeading("Round information", true))
	/**
	 * Loop through the rounds subcollection on the booking
	 */
	await db
		.collection(`bookings/${bookingID}/rounds`)
		.orderBy("tee_time")
		.get()
		.then((roundDocs) => {
			/**
			 * Loop over the round documents
			 */
			let index = 1
			roundDocs.forEach((roundDoc) => {
				/**
				 * Deconstruct the price data from the document
				 */
				const { courseID, tee_time, buggies, comp_buggies } = roundDoc.data()
				/**
				 * Generate a readable string
				 */
				const teeTimeString = moment(tee_time?.seconds, "X").format("DD/MM/YYYY [at] HH:mm")
				/**
				 * Push the round into the email html array
				 */
				html_array.push(tableRow(`Round #${index}`, courses[courseID]))
				html_array.push(tableRow("Tee time:", teeTimeString))

				if (comp_buggies && comp_buggies !== "false" && comp_buggies !== "true") {
					// Find the relevant text needed to send to the resort
					let buggiesText = ""
					switch (comp_buggies) {
						case "yes_reserved":
							buggiesText = "We have reserved buggies for you at the resort be paid locally, these are subject to availability."
							break
						case "yes_greenfee":
							buggiesText = "Buggies are included in your package."
							break
						case "yes_prepaid":
							buggiesText = "Buggies are included in your package."
							break
						default:
							buggiesText = "Buggies not included."
					}
					html_array.push(tableRow("Buggies:", buggiesText))
				} else if (comp_buggies === "false") {
					html_array.push(tableRow("Buggies included:", "Buggies not included."))
				} else if (comp_buggies === "true") {
					html_array.push(tableRow("Buggies included:", "Buggies are included in your package."))
				} else {
					html_array.push(tableRow("Buggies included:", buggies ? "Yes" : "Buggies not included."))
				}

				/**
				 * If the index is smaller than the amount of documents, add a spacer line in
				 */
				if (index < roundDocs.size) {
					html_array.push(tableRowSpace())
				}
				/**
				 * Increment the index
				 */
				index++
			})
		})
	/**
	 * Close the table out
	 */
	html_array.push("</tbody>")
	html_array.push("</table>")
	html_array.push("<br />")
	/**
	 * Send the reservation email
	 */
	await sendEmail({
		template: getsFreeInsurance ? mailTypes.CLIENT_ITINERARY_INSURANCE.template : mailTypes.CLIENT_ITINERARY.template,
		subject: `Booking Confirmation - Ref: ${booking?.reference}`,
		from: {
			name: agent.name,
			email: agent.email,
		},
		user: {
			name: `${client.first_name} ${client.last_name}`,
			email: client.email,
		},
		mail: {
			name: client.first_name,
			custom_html: `${html_array.join("")}`,
		},
		bcc: !reviewBCCSent ? [{ name: "Trustpilot", email: "golfholidaysdirect.com+f71e6c21f2@invite.trustpilot.com" }] : [],
	})
	/**
	 * Set the itinerary email status to sent on the booking
	 */
	await db.doc(`bookings/${bookingID}`).set(
		{
			emails: {
				itinerary: firebase.firestore.FieldValue.serverTimestamp(),
			},
		},
		{ merge: true }
	)
	/**
	 * Add a log into the booking to show it's been sent
	 */
	await db.collection(`bookings/${bookingID}/logs`).add({
		type: "ITINERARY_EMAIL_SENT",
		message: `Itinerary email was sent to ${client.email}`,
		created: firebase.firestore.FieldValue.serverTimestamp(),
		badge: "INFO",
		user: auth.currentUser.uid,
	})
	/**
	 * If the itinerary email had the BCC included
	 */
	if (!reviewBCCSent) {
		/**
		 * Set the itinerary review object on the booking
		 */
		await db.doc(`bookings/${bookingID}`).set(
			{
				review: {
					itinerary_bcc_sent: true,
				},
			},
			{ merge: true }
		)
		/**
		 * Add a log to say they were included
		 */
		await db.collection(`bookings/${bookingID}/logs`).add({
			type: "TRUSPILOT_BCC_INCLUDED",
			message: `The TrustPilot email was included as bcc into the itinerary email`,
			created: firebase.firestore.FieldValue.serverTimestamp(),
			badge: "INFO",
			user: auth.currentUser.uid,
		})
	}
}

// Take in a bookingID to send the client an email detailing the insurance details
export const sendClientItineraryInsurance = async (bookingID) => {
	// Get the booking details from the database
	const booking = await db
		.doc(`bookings/${bookingID}`)
		.get()
		.then((bookingDoc) => {
			return { ...bookingDoc.data() }
		})

	// Fetch the name of the booking agent
	const agent = await db
		.doc(`users/${booking.agent}`)
		.get()
		.then((agentDoc) => {
			const { first_name, last_name, email } = agentDoc.data()

			return { name: `${first_name} ${last_name}`, first_name, email }
		})

	// Get the client details
	const client = await db
		.doc(`clients/${booking.client}`)
		.get()
		.then((clientDoc) => {
			return { ...clientDoc.data() }
		})

	// Check to see if the booking still qualifies for free insurance
	const getsFreeInsurance = moment(booking.booked?.seconds, "X").isAfter("2024-07-18T00:00:00Z")

	if (getsFreeInsurance || true) {
		await sendEmail({
			template: mailTypes.BOOKING_INSURANCE.template,
			subject: `Activate Your Free Insurance - Ref: ${booking?.reference}`,
			from: {
				name: agent.name,
				email: agent.email,
			},
			user: {
				name: `${client.first_name} ${client.last_name}`,
				email: client.email,
			},
			mail: {
				name: client.first_name,
				booking_ref: booking?.reference,
			},
		})
	}

	// Toggle the timestamp for the insurance email
	await db.doc(`bookings/${bookingID}`).set(
		{
			emails: {
				insurance: firebase.firestore.FieldValue.serverTimestamp(),
			},
		},
		{ merge: true }
	)

	// Add a log to the booking to show it's been sent
	await db.collection(`bookings/${bookingID}/logs`).add({
		type: "INSURANCE_EMAIL_SENT",
		message: `An additional email for the insurance was sent to ${client.email}`,
		created: firebase.firestore.FieldValue.serverTimestamp(),
		badge: "INFO",
		user: auth.currentUser.uid,
	})
}

/**
 * Take in a bookingID to send the client their holiday vouchers
 */
export const sendClientHolidayVouchers = async (bookingID, courses) => {
	/**
	 * Get the booking details from the database
	 */
	const booking = await db
		.doc(`bookings/${bookingID}`)
		.get()
		.then((bookingDoc) => {
			/**
			 * Return a complete object of all booking details
			 */
			return { ...bookingDoc.data() }
		})
	/**
	 * Fetch the name of the booking agent
	 */
	const agent = await db
		.doc(`users/${booking.agent}`)
		.get()
		.then((agentDoc) => {
			/**
			 * Get the agent data
			 */
			const { first_name, last_name, email } = agentDoc.data()
			/**
			 * Return the data in an object
			 */
			return { name: `${first_name} ${last_name}`, first_name, email }
		})
	/**
	 * Get the client details
	 */
	const client = await db
		.doc(`clients/${booking.client}`)
		.get()
		.then((clientDoc) => {
			/**
			 * Return a complete object of all the client details
			 */
			return { ...clientDoc.data() }
		})

	/**
	 * Get the group size by adding both golfers and non golfers
	 */
	const group_size = Number(booking.golfers + booking.non_golfers)
	/**
	 * Setup an array to store the html content for the email
	 */
	let html_array = []
	/**
	 * Setup the table
	 */
	html_array.push("<table style='width:100%'>")
	html_array.push("<tbody>")
	/**
	 * Loop through the rounds subcollection on the booking
	 */
	await db
		.collection(`bookings/${bookingID}/rounds`)
		.orderBy("tee_time")
		.get()
		.then((roundDocs) => {
			/**
			 * Loop over the round documents
			 */
			roundDocs.forEach((roundDoc) => {
				/**
				 * Deconstruct the price data from the document
				 */
				const { courseID, tee_time, buggies, comp_buggies } = roundDoc.data()
				/**
				 * Generate a readable string
				 */
				const teeTimeString = moment(tee_time?.seconds, "X").format("DD/MM/YYYY [at] HH:mm")
				/**
				 * Add the round heading
				 */
				html_array.push(tableHeading("Round voucher"))
				/**
				 * Add the booking details
				 */
				html_array.push(tableRow("Lead name:", `${client.first_name} ${client.last_name}`))
				html_array.push(tableRow("Course:", courses[courseID]))
				html_array.push(tableRow("Tee time:", teeTimeString))
				html_array.push(tableRow("Golfers playing:", `${booking?.golfers}`))

				if (comp_buggies && comp_buggies !== "false" && comp_buggies !== "true") {
					// Find the relevant text needed to send to the resort
					let buggiesText = ""
					switch (comp_buggies) {
						case "yes_reserved":
							buggiesText = "We have reserved buggies for you at the resort be paid locally, these are subject to availability."
							break
						case "yes_greenfee":
							buggiesText = "Buggies are included in your package."
							break
						case "yes_prepaid":
							buggiesText = "Buggies are included in your package."
							break
						default:
							buggiesText = "Buggies not included."
					}
					html_array.push(tableRow("Buggies:", buggiesText))
				} else if (comp_buggies === "false") {
					html_array.push(tableRow("Buggies included:", "Buggies not included."))
				} else if (comp_buggies === "true") {
					html_array.push(tableRow("Buggies included:", "Buggies are included in your package."))
				} else {
					html_array.push(tableRow("Buggies included:", buggies ? "Yes" : "Buggies not included."))
				}
			})
		})
	/**
	 * Close the table out
	 */
	html_array.push("</tbody>")
	html_array.push("</table>")
	html_array.push("<br />")
	/**
	 * Send the reservation email
	 */
	await sendEmail({
		template: mailTypes.HOLIDAY_VOUCHERS.template,
		subject: `Your Holiday Vouchers - Ref: ${booking?.reference}`,
		from: {
			name: agent.name,
			email: agent.email,
		},
		user: {
			name: `${client.first_name} ${client.last_name}`,
			email: client.email,
		},
		mail: {
			name: client.first_name,
			custom_html: `${html_array.join("")}`,
		},
	})
	/**
	 * Set the vouchers email status to sent on the booking
	 */
	await db.doc(`bookings/${bookingID}`).set(
		{
			emails: {
				vouchers: firebase.firestore.FieldValue.serverTimestamp(),
			},
		},
		{ merge: true }
	)
	/**
	 * Add a log into the booking to show it's been sent
	 */
	await db.collection(`bookings/${bookingID}/logs`).add({
		type: "VOUCHERS_EMAIL_SENT",
		message: `Vouchers email was sent to ${client.email}`,
		created: firebase.firestore.FieldValue.serverTimestamp(),
		badge: "INFO",
		user: auth.currentUser.uid,
	})
}

/**
 * Take in a bookingID along with a hotelID to send the transfer partner an email containing
 * links to the images uploaded by the client
 */
export const sendTransferFlightImages = async (bookingID, hotelID) => {
	/**
	 * Get the booking details from the database
	 */
	const booking = await db
		.doc(`bookings/${bookingID}`)
		.get()
		.then((bookingDoc) => {
			/**
			 * Return a complete object of all booking details
			 */
			return { ...bookingDoc.data() }
		})
	/**
	 * Fetch the name of the booking agent
	 */
	const agent = await db
		.doc(`users/${booking.agent}`)
		.get()
		.then((agentDoc) => {
			/**
			 * Get the agent data
			 */
			const { first_name, last_name, email } = agentDoc.data()
			/**
			 * Return the data in an object
			 */
			return { name: `${first_name} ${last_name}`, email }
		})
	/**
	 * Get the client details
	 */
	const client = await db
		.doc(`clients/${booking.client}`)
		.get()
		.then((clientDoc) => {
			/**
			 * Return a complete object of all the client details
			 */
			return { ...clientDoc.data() }
		})
	/**
	 * Get the name of the hotel
	 */
	const hotel = await db
		.doc(`bookings/${bookingID}/hotels/${hotelID}`)
		.get()
		.then(async (hotelDoc) => {
			/**
			 * Get the resort ID for the hotel
			 */
			const { resortID } = hotelDoc.data()
			/**
			 * Get the hotel document from the database to reutnr the resort name
			 */
			const hotel_name = await db
				.doc(`hotels/${resortID}`)
				.get()
				.then((hotelDoc) => {
					/**
					 * Return the name for the hotel
					 */
					return hotelDoc.data().name
				})
			/**
			 * Return all hotel data along with the resort name
			 */
			return { ...hotelDoc.data(), hotel_name }
		})
	/**
	 * Get the transfer details
	 */
	const transfer = await db
		.doc(`transfers/${booking.transfer}`)
		.get()
		.then((transferDoc) => {
			/**
			 * Return a complete object of all the transfer partner details
			 */
			return { ...transferDoc.data() }
		})
	/**
	 * Get the check in date
	 */
	const check_in = moment(hotel.check_in?.seconds, "X").format("DD/MM/YYYY")
	/**
	 * Get the departure date by adding the nights to the original check in date
	 */
	const departure = moment(hotel.check_in?.seconds, "X").add(hotel.nights, "days").format("DD/MM/YYYY")
	/**
	 * Setup an array to store the html content for the email
	 */
	let html_array = []
	/**
	 * Setup the table
	 */
	html_array.push("<table style='width:100%'>")
	html_array.push("<tbody>")
	/**
	 * Has a booking for this transfer already been sent?
	 */
	if (booking?.transfer_images_status) {
		/**
		 * Add a booking
		 */
		html_array.push("<tr>")
		html_array.push(`<td colspan='2' style='color:#fff;padding-top:5px;padding-bottom:5px;text-align:center;font-weight:500;background-color:red;'>THESE ARE UPDATED FLIGHT DETAILS</td>`)
		html_array.push("</tr>")
		/**
		 * Add an empty table row beneath the heading
		 */
		html_array.push("<tr>")
		html_array.push(`<td colspan='2' style='padding-top:6px;'></td>`)
		html_array.push("</tr>")
	}
	/**
	 * Add the client name
	 */
	html_array.push(tableRow("Client name:", `${client?.first_name} ${client?.last_name}`))
	/**
	 * Add the arrival date
	 */
	html_array.push(tableRow("Arrival:", check_in))
	/**
	 * Add the depart date
	 */
	html_array.push(tableRow("Departing:", departure))
	/**
	 * Add the amount of PAX on the booking
	 */
	html_array.push(tableRow("Group size:", Number(booking.golfers + booking.non_golfers)))
	/**
	 * Loop through the images under the booking
	 */
	await db
		.collection(`bookings/${bookingID}/images`)
		.get()
		.then((imageDocs) => {
			/**
			 * Loop over the images
			 */
			let index = 1
			imageDocs.forEach((imageDoc) => {
				/**
				 * Deconstruct the public url from the document
				 */
				const { public_url } = imageDoc.data()
				/**
				 * Push the image link into the email html array
				 */
				html_array.push(tableRow(`Flight details #${index}`, `<a href='${public_url}' target='_blank' style='color:red;'>Image link</a>`))
				/**
				 * Increment the index
				 */
				index++
			})
		})
	/**
	 * Close the table out
	 */
	html_array.push("</tbody>")
	html_array.push("</table>")
	/**
	 * Send the reservation email
	 */
	await sendEmail({
		template: mailTypes.FLIGHT_IMAGES.template,
		subject: `Flight details for ${hotel.hotel_name} transfer`,
		from: {
			name: agent.name,
			email: agent.email,
		},
		user: {
			name: transfer.name,
			email: transfer.email,
		},
		mail: {
			custom_html: `${html_array.join("")}`,
		},
	})
	/**
	 * Then we need to update the transfer booking to reflect the booking has been sent
	 */
	await db.doc(`bookings/${bookingID}`).set(
		{
			transfer_images_status: "sent",
		},
		{ merge: true }
	)
	/**
	 * Add a log to the booking to record the email being sent
	 */
	await db.collection(`bookings/${bookingID}/logs`).add({
		type: "FLIGHT_IMAGES_SENT",
		badge: "INFO",
		message: `Flight images were sent to ${transfer?.email}`,
		created: firebase.firestore.FieldValue.serverTimestamp(),
		user: auth.currentUser.uid,
	})
}

/**
 * Take in a booking ID and send a payment reminder to the client attached to it
 */
export const sendPaymentReminderEmail = async (bookingID) => {
	/**
	 * Get the booking details from the database
	 */
	const booking = await db
		.doc(`bookings/${bookingID}`)
		.get()
		.then((bookingDoc) => {
			/**
			 * Return a complete object of all booking details
			 */
			return { ...bookingDoc.data() }
		})
	/**
	 * Fetch the name of the booking agent
	 */
	const agent = await db
		.doc(`users/${booking.agent}`)
		.get()
		.then((agentDoc) => {
			/**
			 * Get the agent data
			 */
			const { first_name, last_name, email } = agentDoc.data()
			/**
			 * Return the data in an object
			 */
			return { name: `${first_name} ${last_name}`, email }
		})
	/**
	 * Get the client details
	 */
	const client = await db
		.doc(`clients/${booking.client}`)
		.get()
		.then((clientDoc) => {
			/**
			 * Return a complete object of all the client details
			 */
			return { ...clientDoc.data() }
		})
	/**
	 * Get the outstanding balance as a string
	 */
	const balanceOutstanding = booking?.balance_outstanding.toLocaleString()
	/**
	 * Build a balance due date as a string
	 */
	const balanceDueDate = moment(booking?.balance_due?.seconds, "X").format("DD/MM/YYYY")
	/**
	 * Send the reminder email
	 */
	await sendEmail({
		template: mailTypes.PAYMENT_REMINDER.template,
		subject: `Payment reminder - Booking ref: ${booking?.reference}`,
		from: {
			name: agent.name,
			email: agent.email,
		},
		user: {
			name: `${client.first_name} ${client.last_name}`,
			email: client.email,
		},
		mail: {
			agent: agent.name,
			name: client.first_name,
			balance_outstanding: `£${balanceOutstanding}`,
			balance_due: `on ${balanceDueDate}`,
		},
	})
	/**
	 * Set the payment reminder email status to sent on the booking
	 */
	await db.doc(`bookings/${bookingID}`).set(
		{
			emails: {
				payment_reminder: firebase.firestore.FieldValue.serverTimestamp(),
			},
		},
		{ merge: true }
	)
	/**
	 * Add a log to the booking to record the email being sent
	 */
	await db.collection(`bookings/${bookingID}/logs`).add({
		type: "PAYMENT_REMINDER_SENT",
		badge: "INFO",
		message: `Payment reminder sent to ${client.email}`,
		created: firebase.firestore.FieldValue.serverTimestamp(),
		user: auth.currentUser.uid,
	})
}

/**
 * Take in a booking ID and send a review request to the client with Truspilot in as a bcc
 */
export const sendReviewRequestEmail = async (bookingID) => {
	/**
	 * Get the booking details from the database
	 */
	const booking = await db
		.doc(`bookings/${bookingID}`)
		.get()
		.then((bookingDoc) => {
			/**
			 * Return a complete object of all booking details
			 */
			return { ...bookingDoc.data() }
		})
	/**
	 * Fetch the name of the booking agent
	 */
	const agent = await db
		.doc(`users/${booking.agent}`)
		.get()
		.then((agentDoc) => {
			/**
			 * Get the agent data
			 */
			const { first_name, last_name, email } = agentDoc.data()
			/**
			 * Return the data in an object
			 */
			return { name: `${first_name} ${last_name}`, email }
		})
	/**
	 * Get the client details
	 */
	const client = await db
		.doc(`clients/${booking.client}`)
		.get()
		.then((clientDoc) => {
			/**
			 * Return a complete object of all the client details
			 */
			return { ...clientDoc.data() }
		})
	/**
	 * Generate a timestamp for sending the email from trustpilot
	 */
	const sendAt = moment().add("30", "seconds").utc().format("YYYY-MM-DD[T]HH:mm:ss")
	/**
	 * Send the reminder email
	 */
	await sendEmail({
		template: mailTypes.REVIEW_REQUEST.template,
		subject: `Welcome Home, from Golf Holidays Direct`,
		from: {
			name: agent.name,
			email: agent.email,
		},
		user: {
			name: `${client.first_name} ${client.last_name}`,
			email: client.email,
		},
		mail: {
			agent: agent.name,
			name: `${client.first_name} ${client.last_name}`,
			email: client.email,
			booking_ref: `R${booking?.reference}`,
			send_time: sendAt,
		},
		bcc: [{ name: "Trustpilot", email: "golfholidaysdirect.com+f71e6c21f2@invite.trustpilot.com" }],
	})
	/**
	 * Set the payment reminder email status to sent on the booking
	 */
	await db.doc(`bookings/${bookingID}`).set(
		{
			emails: {
				review_request: firebase.firestore.FieldValue.serverTimestamp(),
			},
		},
		{ merge: true }
	)
	/**
	 * Add a log to the booking to record the email being sent
	 */
	await db.collection(`bookings/${bookingID}/logs`).add({
		type: "REVIEW_REQUEST_SENT",
		badge: "INFO",
		message: `A review request was sent to ${client.email}`,
		created: firebase.firestore.FieldValue.serverTimestamp(),
		user: auth.currentUser.uid,
	})
}
