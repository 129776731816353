import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { db } from "../../utils/firebase"
import moment from "moment"

// Functional component to return the booking with an outstanding balance
export default function Booking({ id, details, maskDetails }) {
	const [agent, setAgent] = useState({})
	const [client, setClient] = useState({})

	// Generate some date strings from the database timestamps
	const bookedDate = moment(details.booked?.seconds, "X").format("MMM D[,] YYYY")
	const timeSinceBooking = moment().diff(moment(details.booked?.seconds, "X"), "days")

	// On component load
	useEffect(() => {
		if (details?.client) {
			db.doc(`clients/${details.client}`)
				.get()
				.then((clientDoc) => {
					if (clientDoc.exists) {
						setClient(clientDoc.data())
					}
				})
		}

		if (details?.agent) {
			db.doc(`users/${details.agent}`)
				.get()
				.then((agentDoc) => {
					if (agentDoc.exists) {
						setAgent(agentDoc.data())
					}
				})
		}
	}, [])

	return (
		<tr>
			<td>
				{!maskDetails && (
					<NavLink to={`client/${details.client}`}>
						{client.first_name && `${client.first_name} ${client.last_name}`}
						{!client.first_name && <small className="no-value">No name set</small>}
					</NavLink>
				)}

				{maskDetails && (
					<>
						{client.first_name && `${client.first_name} ${client.last_name}`}
						{!client.first_name && <small className="no-value">No name set</small>}
					</>
				)}
			</td>
			<td>
				{!maskDetails && (
					<>
						{client?.phone && <>{client.phone}</>}
						{!client?.phone && <small className="no-value">No number set</small>}
					</>
				)}

				{maskDetails && (
					<>
						{client?.phone && <>{client.phone?.replace(/./g, "*")}</>}
						{!client?.phone && <small className="no-value">No number set</small>}
					</>
				)}
			</td>
			<td>{details.golfers + details.non_golfers}</td>
			<td>£{(details.golfers + details.non_golfers) * 50}</td>
			<td>£{details.paid_by_client}</td>
			<td>
				{agent?.first_name && `${agent.first_name} ${agent.last_name}`}
				{!agent?.first_name && <small className="no-value">No agent assigned</small>}
			</td>
			<td>
				{bookedDate === "Invalid date" && <small>No due date set</small>}
				{bookedDate !== "Invalid date" && bookedDate} ({timeSinceBooking} days ago)
			</td>
			<td className="is-button">
				<NavLink to={`booking/${id}`}>View booking</NavLink>
			</td>
		</tr>
	)
}
