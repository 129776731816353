import { useState, useEffect, useRef } from "react"
import { auth, db } from "../../../../utils/firebase"
import { Editor } from "@tinymce/tinymce-react"
import firebase from "firebase"
import "./content.scss"

// UI components
import Loading from "../../../app/loading/loading"
import Button from "../../../ui/button/button"

// A list of all entities that have content managed via the CMS
const entities = {
	GHD: "Golf Holidays Direct",
	GBS: "Golf Breaks Spain",
	GBP: "Golf Breaks Portugal",
}

// Returns the HTML markup for the linked courses view on a holiday
export default function HolidayContent({ holiday }) {
	const [loading, setLoading] = useState(true)
	const [saving, setSaving] = useState(false)
	const [activeEntity, setActiveEntity] = useState("")
	const [description, setDescription] = useState("")

	// Pull the holiday ID from the parameters
	const { id: holidayID, title, tags } = holiday

	// Setup a reference to the editor DOM element
	const editorRef = useRef(null)

	// When the component is loaded
	useEffect(() => {
		setTimeout(() => {
			// Set the active entitity to GHD
			changeActiveEntity("GHD")
			setLoading(false)
		}, 1000)
	}, [])

	// Save the current editors content into the database
	const saveEditorsContent = async () => {
		if (editorRef.current) {
			// Does the content match what we already have?
			if (editorRef.current.getContent() === description) return null

			// Otherwise toggle the saving state
			setSaving(true)

			// Add a new record into the content collection
			await db.collection("CONTENT_holidays").add({
				holidayID: holidayID,
				entityID: activeEntity,
				updated: firebase.firestore.FieldValue.serverTimestamp(),
				title: title,
				description: editorRef.current.getContent(),
				tags: tags,
				updated_by: auth.currentUser.uid,
			})

			// Reset the loading state
			setSaving(false)
		}
	}

	// When the activeEntity is updated
	const changeActiveEntity = async (entityID) => {
		// Clear the loading state
		setLoading(true)
		setActiveEntity("")
		setDescription(null)
		setSaving(false)

		// Pull the description from the current database document
		const { description: currentDescription } = await db
			.collection("CONTENT_holidays")
			.orderBy("updated", "desc")
			.where("holidayID", "==", holidayID)
			.where("entityID", "==", entityID)
			.limit(1)
			.get()
			.then((contentDocs) => {
				if (contentDocs.docs?.length > 0) {
					return { id: contentDocs.docs[0].id, description: contentDocs.docs[0].data()?.description }
				} else {
					return { id: null, description: null }
				}
			})

		// Set the description into the state
		editorRef.current.setContent("")
		if (currentDescription) {
			setDescription(currentDescription)
			if (editorRef.current) {
				editorRef.current.setContent(currentDescription || "")
			}
		} else {
			setDescription("")
			if (editorRef.current) {
				editorRef.current.setContent("")
			}
		}

		// Reset the loading states
		setLoading(false)
		setActiveEntity(entityID)
	}

	return (
		<>
			<div className={["content-tabs", loading ? "isDisabled" : ""].join(" ")}>
				<div
					className={["content-toggle-tab", activeEntity === "GHD" ? "isActive" : ""].join(" ")}
					onClick={() => changeActiveEntity("GHD")}>
					Golf Holidays Direct
				</div>
				<div
					className={["content-toggle-tab", activeEntity === "GBS" ? "isActive" : ""].join(" ")}
					onClick={() => changeActiveEntity("GBS")}>
					Golf Breaks Spain
				</div>
				<div
					className={["content-toggle-tab", activeEntity === "GBP" ? "isActive" : ""].join(" ")}
					onClick={() => changeActiveEntity("GBP")}>
					Golf Breaks Portugal
				</div>
			</div>

			<div className="currently-editing">
				{loading && <Loading />}

				{!loading && (
					<p>
						Currently editing: <strong>{entities[activeEntity]}</strong>
					</p>
				)}
			</div>

			<div className="editor-wrapper">
				<Editor
					apiKey="b0ogx1dhxqu4h60f5jexew40xlg2l640dtj1zjbw1eiriovb"
					onInit={(_evt, editor) => (editorRef.current = editor)}
					init={{
						height: 650,
						menubar: false,
						relative_urls: false,
						remove_script_host: true,
						document_base_url: "/",
						plugins: ["link", "code"],
						toolbar: "undo redo | blocks | " + "bold italic link | alignleft aligncenter " + "alignright alignjustify | bullist numlist | " + "removeformat | code | help",
						content_style: "body { font-family:'Poppins', sans-serif; font-size:15px; line-height:1.6em; } h1, h2, h3, h4, h5, h6 { font-weight: 600; }",
					}}
				/>
			</div>

			<br />

			{!loading && activeEntity && (
				<Button
					onClick={saveEditorsContent}
					label={`Save to ${entities[activeEntity]}`}
					loading={saving}
					loadingText="Saving..."
				/>
			)}
		</>
	)
}
