import { useEffect, useState } from "react"
import { db, auth } from "../../utils/firebase"

import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Table from "../../components/structure/table/table"
import QuoteRow from "./quote/quote"

export default function MyQuotes() {
	const [quotes, setQuotes] = useState([])

	useEffect(() => {
		// Find all the quotes attached to this user
		db.collection("quotes")
			.orderBy("created", "desc")
			.where("agent", "==", auth.currentUser.uid)
			.limit(200)
			.get()
			.then((quoteDocs) => {
				let quotesArr = []

				// Loop over each of the quote documents
				quoteDocs.forEach((enquiry) => {
					// Push the quote details onto the array
					quotesArr.push({
						id: enquiry.id,
						...enquiry.data(),
					})
				})

				// Then set these into the state
				setQuotes(quotesArr)
			})
	}, [])

	return (
		<Tile fullPage={true}>
			<Title>
				<h1>Sent Quotes</h1>
				<p>Showing the last 200 quotes sent by you</p>
			</Title>

			<Table
				className="enquiries-table"
				headings={["Created", "Agent", "Client", "Reference(s)"]}
				noResults={quotes.length === 0}
				noResultsMessage="Fetching your quotes...">
				{/* Print out the quotes for this user */}
				{quotes.map((quote) => (
					<QuoteRow
						key={quote.id}
						id={quote.id}
						details={quote}
						deleting={false}
						deleteQuote={null}
					/>
				))}
			</Table>
		</Tile>
	)
}
