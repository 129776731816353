import { useEffect, useState } from "react"
import { db } from "../../../utils/firebase"
import { NavLink } from "react-router-dom"
import moment from "moment"
import "./quote.scss"

// UI components
import QuoteDocument from "../../../components/client/quotes/components/document"

// Returns the HTML markup for a quote row
export default function QuoteRow({ details, deleteQuote, deleting }) {
	const [agent, setAgent] = useState("")
	const [client, setClient] = useState("")

	// Create a date string for the created date
	const createdAt = moment(details.created?._seconds || details.created?.seconds, "X").format("MMM D[,] YYYY [at] HH:mm:ss")

	// On component load
	useEffect(() => {
		// Fetch the agent details attached to the quote
		db.doc(`users/${details.agent}`)
			.get()
			.then((agentDoc) => {
				// Set the agent data into the state
				setAgent(agentDoc.data())
			})

		db.doc(`clients/${details.client}`)
			.get()
			.then((clientDoc) => {
				// Set the client data into the state
				setClient(clientDoc.data())
			})
	}, [])

	return (
		<tr>
			<td>{createdAt}</td>
			<td>
				{agent?.first_name && `${agent?.first_name} ${agent?.last_name}`}
				{!agent?.first_name && <small className="no-value">No agent assigned</small>}
			</td>
			<td>
				{client.first_name && <NavLink to={`client/${details.client}`}>{client.first_name && `${client.first_name} ${client.last_name}`}</NavLink>}
				{!client.first_name && <small className="no-value">Client account removed</small>}
			</td>
			<td className="client-quote-references">
				{details.packages?.map((quoteObject) => (
					<QuoteDocument
						quoteID={details.id}
						quotePackage={quoteObject}
					/>
				))}
			</td>
		</tr>
	)
}
