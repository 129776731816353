export const CLIENT_ITINERARY = {
	template: "client-itinerary",
}

export const CLIENT_ITINERARY_INSURANCE = {
	template: "client-itinerary-with-insurance",
}

export const HOLIDAY_VOUCHERS = {
	template: "holiday-vouchers",
}

export const FLIGHT_IMAGES = {
	template: "flight-images",
}

export const TRANSFER_BOOKING = {
	template: "transfer-booking",
}

export const HOTEL_BOOKING = {
	template: "hotel-booking",
}

export const BOOKING_BOILERPLATE = {
	template: "booking-boilerplate",
}

export const PAYMENT_REMINDER = {
	template: "payment-reminder",
}

export const ATOL_CERTIFICATE = {
	template: "atol-certificate",
}

export const REVIEW_REQUEST = {
	template: "review-request-w-trustpilot",
}

export const GENERATED_QUOTE = {
	template: "generated-quote",
}

export const BOOKING_INSURANCE = {
	template: "booking-insurance",
}
