import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../utils/providers/auth"
import { db } from "../../utils/firebase"
import firebase from "firebase"
import moment from "moment"
import "./deposits.scss"

// UI components
import { LoadingIcon } from "../../utils/svgs"
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Table from "../../components/structure/table/table"
import Booking from "../../components/deposits/booking"

// Functional component to return the overdue deposits page
export default function Deposits() {
	const [loading, setLoading] = useState(true)
	const [bookings, setBookings] = useState([])

	// Get some timestamps for the query
	const fetchUpto = moment().subtract(4, "weeks").endOf("day").valueOf()
	const fetchFrom = moment("01/01/2024", "DD/MM/YYYY").startOf("day").valueOf()

	// Pull some data from the auth context
	const { user } = useContext(AuthContext)

	// On component load
	useEffect(() => {
		// Query the database for all the bookings
		db.collection("bookings")
			.where("booked", "<=", firebase.firestore.Timestamp.fromMillis(fetchUpto))
			.where("booked", ">", firebase.firestore.Timestamp.fromMillis(fetchFrom))
			.orderBy("booked", "desc")
			.get()
			.then((bookingDocs) => {
				let searchBookings = []
				bookingDocs.forEach((bookingDoc) => {
					// Pull some data from the booking
					const { agent, client, booked, removed, paid_by_client, golfers, non_golfers, hide_from_overdue } = bookingDoc.data()

					// If the agent or client is null, return
					if (agent !== null && agent !== "" && client !== null && client !== "") {
						// Calculate the time in days since the booking
						const timeSinceBooking = moment().diff(moment(booked?.seconds, "X"), "days")

						// Get the total group size and deposit required
						const groupSize = golfers + non_golfers
						const depositRequired = groupSize * 50

						if (!hide_from_overdue) {
							if ((!removed && paid_by_client < 100) || (!removed && timeSinceBooking > 28 && paid_by_client < depositRequired)) {
								searchBookings.push({ id: bookingDoc.id, ...bookingDoc.data() })
							}
						}
					}
				})

				// Set the bookings in and reset the loading state
				setBookings(searchBookings)
				setLoading(false)
			})
	}, [])

	return (
		<Tile fullPage={true}>
			<Title className="flex has-select-field">
				<h1>Overdue Deposits</h1>
			</Title>

			<Table
				className="bookings-table"
				headings={["Name", "Telephone", "Group size", "Group deposit", "Paid by client", "Agent assigned", "Booked on", ""]}>
				{bookings.map((booking) => (
					<Booking
						key={booking.id}
						id={booking.id}
						details={booking}
						maskDetails={user?.mask_details}
					/>
				))}

				{loading && (
					<div className="table-loading-splash">
						<div className="loading-wrapper">
							<p>Loading...</p>
							<div className="svg-loading-wrap">
								<LoadingIcon />
							</div>
						</div>
					</div>
				)}
			</Table>
		</Tile>
	)
}
