import React, { useEffect, useState } from "react"
import { db } from "../../utils/firebase"
import { NavLink } from "react-router-dom"
import moment from "moment"

// UI components
import Badge from "../../components/ui/badge/badge"

// Different options for the buggy flags
const buggyOptions = {
	true: "[IGNORE] Yes",
	false: "[IGNORE] No",
	no: "Not included",
	yes_reserved: "Reserved to pay locally",
	yes_greenfee: "Included in green fee",
	yes_prepaid: "Included and prepaid to GHD",
}

// Returns a row for the buggies table
export default function BuggyRow(props) {
	const [client, setClient] = useState({})
	const [agent, setAgent] = useState({})
	const [booking, setBooking] = useState({})
	const [courseName, setCourseName] = useState("")

	// Deconstruct the details from the props
	const { details } = props

	// Create a new string for the reserved buggies
	let reservedString = ""
	let reservedFlag = false

	// Allocate the correct string for whether the buggies are reserved
	if (
		buggyOptions[details.round_details?.comp_buggies]?.length > 0 &&
		buggyOptions[details.round_details?.comp_buggies] !== false &&
		details.round_details?.comp_buggies !== "false" &&
		details.round_details?.comp_buggies !== false
	) {
		reservedString = buggyOptions[details.round_details?.comp_buggies]
	} else {
		reservedFlag = true
		if (details.round_details?.buggies) {
			reservedString = "[IGNORE] Yes"
		} else {
			reservedString = "[IGNORE] No"
		}
	}

	// Generate a string to represent the check_in date from the timestamps
	const checkInDate = moment(booking?.check_in?.seconds, "X").format("MMM D[,] YYYY")
	const bookedDate = moment(booking?.booked?.seconds, "X").format("MMM D[,] YYYY")
	const teeTime = moment(details?.round_details?.tee_time?.seconds, "X").format("MMM D[,] YYYY HH:mm")

	useEffect(() => {
		// Fetch the course name
		if (details?.round_details?.courseID) {
			db.doc(`courses/${details.round_details.courseID}`)
				.get()
				.then((doc) => {
					setCourseName(doc.data().name)
				})
		}
	}, [details])

	// On row mount
	useEffect(() => {
		db.doc(`bookings/${details.bookingID}`)
			.get()
			.then((doc) => {
				setBooking(doc.data())

				const { agent, client } = doc.data()

				db.doc(`clients/${client}`)
					.get()
					.then((clientDoc) => {
						setClient(clientDoc.data())
					})

				db.doc(`users/${agent}`)
					.get()
					.then((agentDoc) => {
						setAgent(agentDoc.data())
					})
			})
	}, [])

	return (
		<tr>
			<td>
				{agent?.first_name && `${agent?.first_name} ${agent?.last_name}`}
				{!agent?.first_name && <small className="no-value">No name yet</small>}
			</td>
			<td>{booking?.reference}</td>
			<td>
				{client.first_name && `${client.first_name} ${client.last_name}`}
				{!client.first_name && <small className="no-value">No name set</small>}
			</td>
			<td>{checkInDate}</td>
			<td>{teeTime}</td>
			<td>{booking?.golfers + booking?.non_golfers}</td>
			<td>{courseName}</td>
			<td>£{isNaN(details?.round_details?.cost_per_golfer) ? 0 : details?.round_details?.cost_per_golfer?.toLocaleString() || 0}</td>
			<td>£{details?.round_details?.buggies_cost?.toLocaleString() || 0}</td>
			<td>
				{!reservedFlag && `${reservedString}`}
				{reservedFlag && <small className="no-value">Unconfirmed: {details?.round_details?.buggies ? "Yes" : "No"}</small>}
			</td>
			<td>
				{details?.round_details?.buggies_booked && (
					<Badge
						label="Yes"
						type="POSITIVE"
					/>
				)}

				{!details?.round_details?.buggies_booked && (
					<Badge
						label="No"
						type="NEGATIVE"
					/>
				)}
			</td>
			<td className="is-button">
				<NavLink to={`booking/${details.bookingID}`}>View booking</NavLink>
				<a
					target="_blank"
					rel="noreferrer"
					href={`booking/${details.bookingID}`}>
					View in new tab
				</a>
			</td>
		</tr>
	)
}
