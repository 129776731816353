import React, { useEffect, useState } from "react"
import { db } from "../../utils/firebase"
import { NavLink } from "react-router-dom"
import moment from "moment"

/**
 * UI components
 */
import Badge from "../ui/badge/badge"

/**
 * Functional component to return the booking
 */
function Booking(props) {
	const [client, setClient] = useState({})
	const [agent, setAgent] = useState({})
	const [returnDate, setReturnDate] = useState("")

	/**
	 * Deconstruct the details from the props
	 */
	const { id, details, show_email, update_local_totals, update_local_pax } = props

	/**
	 * Get the margin values from the sales margins
	 */
	const { flights_cost, agents_margin, customer_price, profit } = details?.margins || {}

	const { golfers, non_golfers } = details

	/**
	 * Generate a string to represent the check_in date from the timestamps
	 */
	const checkInDate = moment(details.check_in?.seconds, "X").format("MMM D[,] YYYY")
	const bookedDate = moment(details.booked?.seconds, "X").format("MMM D[,] YYYY")

	/**
	 * Work out a margin for the booking
	 */
	const bookingProfitPercent = profit >= 0 && customer_price > 0 ? ((profit / customer_price) * 100).toFixed(2) : -100

	/**
	 * Find the total gropu size for the booking
	 */
	const groupSize = details.golfers + details.non_golfers

	/**
	 *
	 */
	useEffect(() => {
		update_local_totals(id, "MARGIN", details?.margins?.profit)
		update_local_totals(id, "COMMISION", agents_margin)

		const flying = !isNaN(parseInt(golfers)) ? parseInt(golfers) : 0
		const notFlying = !isNaN(parseInt(non_golfers)) ? parseInt(non_golfers) : 0

		update_local_pax(id, flying + notFlying, flights_cost > 0)
	}, [])

	/**
	 * On component load
	 */
	useEffect(() => {
		/**
		 * Get the client record from the database
		 */
		db.doc(`clients/${details.client}`)
			.get()
			.then((clientDoc) => {
				/**
				 * Set the client data into the local state
				 */
				setClient(clientDoc.data())
			})

		db.doc(`users/${details.agent}`)
			.get()
			.then((agentDoc) => {
				setAgent(agentDoc.data())
			})

		// Get all the hotels from the booking
		db.collection(`bookings/${id}/hotels`)
			.get()
			.then((hotelDocs) => {
				let firstCheckIn = null
				let totalNights = 0

				hotelDocs.forEach((hotelDoc) => {
					const { check_in, nights } = hotelDoc.data()

					totalNights += nights

					// If the check in date is before the current first check in date
					if (!firstCheckIn || check_in.seconds < firstCheckIn) {
						firstCheckIn = check_in.seconds
					}
				})

				setReturnDate(moment(firstCheckIn, "X").add(totalNights, "days").format("MMM D[,] YYYY"))
			})
	}, [])

	return (
		<tr>
			<td>
				{details.missing_values && <div className="no-values-tag"></div>}

				{bookedDate}
			</td>
			<td>{details?.reference}</td>
			<td>{details?.enquiry?.site}</td>
			<td>
				{agent?.first_name} {agent?.last_name}
			</td>
			<td>
				{client.first_name && `${client.first_name} ${client.last_name}`}
				{!client.first_name && <small className="no-value">No name set</small>}
			</td>
			{show_email && <td>{client.email}</td>}
			<td>{checkInDate}</td>
			<td>
				{returnDate}
				{!returnDate && <small className="no-value">Return not calculated</small>}
			</td>
			<td>{groupSize}</td>
			<td>{details.booked_location ? details.booked_location : details.enquiry?.location}</td>
			<td>£{details?.margins?.flights_cost?.toLocaleString() || 0}</td>
			<td>£{details?.margins?.customer_price?.toLocaleString() || 0}</td>
			<td>£{details?.paid_by_client?.toLocaleString() || 0}</td>
			<td>£{details?.margins?.supplier_invoices?.toLocaleString() || 0}</td>
			<td>
				£{details?.margins?.profit?.toLocaleString()} ({bookingProfitPercent}%)
			</td>
			<td>£{agents_margin?.toLocaleString()}</td>
			<td>
				{details.confirmed && (
					<Badge
						label="Yes"
						type="POSITIVE"
					/>
				)}

				{!details.confirmed && (
					<Badge
						label="No"
						type="NEGATIVE"
					/>
				)}
			</td>
			<td>
				{details.resorts_paid && (
					<Badge
						label="Yes"
						type="POSITIVE"
					/>
				)}

				{!details.resorts_paid && (
					<Badge
						label="No"
						type="NEGATIVE"
					/>
				)}
			</td>
			<td className="is-button">
				<NavLink to={`booking/${id}`}>View booking</NavLink>
				<a
					target="_blank"
					rel="noreferrer"
					href={`booking/${id}`}>
					View in new tab
				</a>
			</td>
		</tr>
	)
}

export default Booking
